<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 text-left">
							{{ translate('date_time') }}
						</th>
						<th
							class="border-top-0 text-left">
							{{ translate('expense_id') }}
						</th>
						<th
							class="border-top-0 text-left">
							{{ translate('name') }}
						</th>
						<th
							class="border-top-0 text-left">
							{{ translate('description') }}
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('amount') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="text-left">
							{{ item.attributes.date }}
						</td>
						<td class="text-left">
							{{ item.attributes.expense_id }}
						</td>
						<td>
							{{ item.attributes.expense_name }}
						</td>
						<td>
							{{ item.attributes.expense_description }}
						</td>
						<td class="text-right">
							{{ item.attributes.amount }}
						</td>
					</tr>
					<tr class="text-right">
						<td
							colspan="5">
							<b />
						</td>
						<td class="text-right">
							<b>{{ totals.amount }}</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import {
	MDY_FORMAT as mdyFormat, YMDHMS_FORMAT,
} from '@/settings/Dates';
import { Grids } from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import Sales from '@/util/Report';
import { DEFAULT_COUNTRY_CURRENCY } from '@/settings/Country';

export default {
	name: 'SettlementExpenses',
	messages: [Grids],
	mixins: [FiltersParams],
	data() {
		return {
			expenses: new Sales(),
			dateFormat: YMDHMS_FORMAT,
			defaultCurrency: DEFAULT_COUNTRY_CURRENCY,
		};
	},
	computed: {
		loading() {
			return !!this.expenses.data.loading;
		},
		errors() {
			return this.expenses.data.errors;
		},
		pagination() {
			return this.expenses.data.pagination;
		},
		data() {
			try {
				const { data } = this.expenses.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		totals() {
			try {
				const { totals } = this.expenses.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		this.$parent.$parent.$parent.rangeStart = '';
		this.$parent.$parent.$parent.rangeEnd = '';
		this.expenses.getSettlementExpenses().then(() => {
			const { start, end } = this.expenses.data.response.data.meta.period_dates;
			this.$parent.$parent.$parent.rangeStart = this.$moment(start.date).format(mdyFormat);
			this.$parent.$parent.$parent.rangeEnd = this.$moment(end.date).format(mdyFormat);
		});
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.$router.push({ name: this.$route.name, query: options });
		},
	},
};
</script>
